<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>顧客情報詳細</template>
              <template v-slot:body>
                <table class="table">
                  <tbody>
                    <tr v-for="item in labels" :key="item.index">
                      <th>{{ item.name }}</th>
                      <td :class="item.class">{{ dataTable[item.key] }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section" v-if="!isCpmAndClientChild">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>セキュリティ</template>
              <template v-slot:body>
                <div class="scrollX">
                  <table class="listTable">
                    <tbody>
                      <tr>
                        <th>パスコード設定</th>
                        <template v-if="customerDetail.hasPasscode">
                          <td class="listTable-fix" :class="{ 'listTable-toggle': usePaymentPasscode }">
                            <label class="form-toggle" v-if="usePaymentPasscode">
                              <input
                                class="form-toggle-input"
                                type="checkbox"
                                name="receptionStoreEmailFlag"
                                v-model="customerDetail.usePaymentPasscode"
                                @change="handleUsePaymentPasscode"
                              />
                              <span class="form-toggle-set">
                                <span class="form-toggle-bg"></span>
                                <span class="form-toggle-circle"></span>
                              </span>
                            </label>
                            あり
                          </td>
                          <td class="listTable-fix">
                            <ActionButton class="btn btn-black" :handle-submit="handleDeletePasscode" button-text="リセットする" />
                          </td>
                        </template>
                        <template v-else>
                          <td class="listTable-fix" v-if="usePaymentPasscode">
                            <label class="form-toggle">
                              <input
                                class="form-toggle-input"
                                type="checkbox"
                                name="receptionStoreEmailFlag"
                                v-model="customerDetail.usePaymentPasscode"
                                @change="handleUsePaymentPasscode"
                              />
                              <span class="form-toggle-set">
                                <span class="form-toggle-bg"></span>
                                <span class="form-toggle-circle"></span>
                              </span>
                            </label>
                          </td>
                          <td class="listTable-fix">なし</td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <router-link class="btn btn-white" :to="{ name: 'EventUser' }">
              <i class="aikon aikon-arrow_left"></i>
              顧客情報一覧に戻る
            </router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDateAndTime, formatDate, breakLineWithComma, generateRandomId } from '@/helpers/formatData';
import { StatusConstants } from '@/constants/status';

export default {
  name: 'EventUserDetail',
  data: function() {
    return {
      pageName: '顧客管理',
    };
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      customerDetail: 'customer/customerDetail',
      isClientChild: 'auth/isClientChild',
      parentEventDetail: 'event/parentEventDetail',
    }),
    labels() {
      return [
        { key: 'number', name: 'user ID', class: 'pre-line' },
        { key: 'name', name: 'お名前' },
        { key: 'kanaName', name: 'フリガナ'},
        { key: 'birthday', name: '生年月日' },
        { key: 'gender', name: '性別'},
        { key: 'zipcode', name: '郵便番号'},
        { key: 'telephone', name: '電話番号'},
        ...([this.isCpmActiveAndHasSwitchCard ? { key: 'email', name: 'メールアドレス / ID' } : null]),
        ...([this.isCpmActiveAndHasSwitchCard ? { key: 'password', name: 'パスワード' } : null]),
        ...([this.parentEventDetail?.useSms && this.isCpmActiveAndHasSwitchCard ? { key: 'mobilephone', name: '携帯電話番号' } : null]),
        { key: 'createDate', name: '登録日時' },
      ].map(e => ({ ...e, id: generateRandomId() })).filter(elm => elm.key);
    },
    subdomain() {
      return this.$route.params?.subdomain || null;
    },
    isCpmActiveAndHasSwitchCard() {
      return !this.customerDetail?.isCpm || (this.isActiveCpmUser && this.parentEventDetail.useSwitchCPMCard);
    },
    customerId() {
      return this.$route.params.customerId;
    },
    isActiveCpmUser() {
      return this.customerDetail?.kanaMei && this.customerDetail?.kanaSei && this.customerDetail?.nameMei && this.customerDetail?.nameSei
        && this.customerDetail?.telephone && this.customerDetail?.zipcode;
    },
    dataTable() {
      return {
        ...this.customerDetail,
        number: this.customerDetail?.hasSwitchCpm ? breakLineWithComma((this.customerDetail?.numberPut ? (this.customerDetail.numberPut + ',') : '') + this.customerDetail?.number) : this.customerDetail?.number,
        gender: StatusConstants.gender[this.customerDetail.gender],
        name: (this.customerDetail.nameSei ?? '') + ' ' + (this.customerDetail.nameMei ?? ''),
        kanaName: (this.customerDetail.kanaSei ?? '') + ' ' + (this.customerDetail.kanaMei ?? ''),
        birthday: formatDate(this.customerDetail.birthday),
        createDate: formatDateAndTime(this.customerDetail.createDate),
        password: '********',
      };
    },
    isCpmAndClientChild() {
      return this.customerDetail?.isCpm && this.isClientChild;
    },
    usePaymentPasscode() {
      return !!this.parentEventDetail?.usePaymentPasscode;
    }
  },
  methods: {
    async handleDeletePasscode() {
      const result = await this.dispatchAction('customer/deletePasscode');
      if (result) {
        this.$message.deleted('passcode');
      }
    },
    dispatchAction(actionPath, payload) {
      return this.$store.dispatch(actionPath, {
        subdomain: this.subdomain,
        customerId: this.customerId,
        ...payload,
      })
    },
    async handleUsePaymentPasscode() {
      const result = await this.$store.dispatch('customer/updateUsePaymentPasscode', {
        usePaymentPasscode: this.customerDetail?.usePaymentPasscode,
        subdomain: this.subdomain,
        customerId: this.customerId,
      });
      if (result) {
        this.$message.showSuccess('changeToggleSuccess');
        await this.$store.dispatch('event/getParentEventDetail', this.subdomain);
      }
    }
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
      await this.dispatchAction('customer/getCustomerDetail'),
      this.$store.dispatch('event/getParentEventDetail', this.$route.params?.subdomain)
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  },
};
</script>
